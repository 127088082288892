@import '../../variables.scss';

.signup {
  // width: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
  // flex-direction: column;

  #{&}__container {
    display: inline-block;
    padding: 2rem;
  }
}

$mobile: 800px;

@function weight($weightName) {
  @return map-get($fontWeights, $weightName)
};

@mixin flexCenter($direction) {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: $direction;
};

@mixin mobile {
  @media (max-width: $mobile) {
    @content
  }
};

@mixin theme($lightTheme: true) {
  @if lightTheme {
    background-color: #fff;
  }
};

.main {
  @include flexCenter(row);
  @include theme();
  @include mobile() {
    flex-direction: column;
  };

  color: $primaryColor;
  font-weight: map-get($fontWeights, bold);
  font-size: 30px;
  width: 80%;
  margin: 0 auto;

  #{&}__paragraph1 {
    font-size: 14px;
    color: $secondaryColor;

    &:hover {
      color: $primaryColor;
    }
  }

  #{&}__paragraph2 {
    @extend .main__paragraph1;
    color: $accent2;
  }
}

