#covers {
	&.book {
		text-align: center;
		user-select: none;
	}
	.coverWrapper {
		width: 150px;
		height: 150px;
		margin: 0 auto;
		display: inline-block;
		vertical-align: top;
		cursor: pointer;
		.coverContainer {
			position: relative;
			width: 100%;
			height: 100%;
			box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
				0px 2px 2px 0px rgba(0, 0, 0, 0.14),
				0px 3px 1px -2px rgba(0, 0, 0, 0.12);
			padding: 20px;

			.imagesWrapper {
				width: 100%;
				height: 100%;
			}

			.imgContainer {
				display: inline-block;
				width: 25%;
				height: 25%;
				padding: 1px;
				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}

		.backCoverLogoContainer {
			position: absolute;
			left: 0;
			bottom: 15px;
			width: 100%;
			text-align: center;

			img {
				width: 20px;
			}
		}

		.backCoverImageWrapper {
			padding-top: 20px;
			text-align: center;
			img {
				width: 60px;
				height: 60px;
				object-fit: cover;
			}
		}
		.mgs {
			text-align: center;
			font-size: 0.48em;
		}
	}

	.bookSpine {
		box-shadow: -1px 1px 5px 0px rgba(0, 0, 0, 0.2),
			0px 2px 2px 0px rgba(0, 0, 0, 0.14),
			0px 3px 1px -2px rgba(0, 0, 0, 0.12);
		background: white;
		position: relative;
		display: inline-block;
		width: 14px;
		height: 150px;
		vertical-align: top;
		cursor: pointer;
		.bookTitle {
			position: absolute;
			top: 10px;
			height: 110px;
			width: 14px;
			font-size: 0.6em;
			overflow: hidden;
			line-height: 14px;
			writing-mode: vertical-lr;
			text-orientation: mixed;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		img {
			position: absolute;
			bottom: 20px;
			left: 2px;
			width: 10px;
		}
	}

	.pageLoadingWrapper {
		width: initial !important;
		position: absolute;
		top: 0;
		left: 0;
		right: 4px;
		bottom: 0;
		background: white;
		margin: 0 !important;
		.pageLoadingGraphic {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
		}
	}
}
