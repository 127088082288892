$primary: #30ccc2;
.ant-layout {
	max-height: initial !important;
}
.ant-layout-content {
	display: flex;
	align-items: stretch;
}
#photosUpload {
	.titleWrapper {
		text-align: center;
		.contentTitle {
			font-size: 22px;
			.titleCheckIconWrapper {
				display: flex;
				align-items: center;
				margin-right: 10px;
				color: $primary;
			}
		}
	}

	.contentTitle.subtitle {
		font-size: 15px;
		margin: 0;
		text-align: center;
	}

	.buttonWrapper {
		text-align: center;
		position: fixed;
		width: 100%;
		left: 0;
		bottom: 15px;
		z-index: 3;
	}

	.paper {
		position: relative;
		width: 103px;
		height: 103px;
		padding: 4px;
		cursor: pointer;
		margin: 0 auto;
		user-select: none;
	}
	.image {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

	.selectedOverlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		z-index: 2;
		background-color: rgba(255,255,255,0.5);
		.checkIcon {
			position: absolute;
			right: 10px;
			bottom: 10px;
		}
	}

	.progressWrapper {
		width: 103px;
		padding-top: 5px;
		margin: 0 auto;
	}

	.progressColorSuccess {
		background-color: #c8e6c9;
	}

	.progressBarColorSuccess {
		background-color: #4caf50;
	}

	.progressColorError {
		background-color: #d35b47;
	}

	.progressBarColorError {
		background-color: #d35b47;
	}

	.bookLoadingWrapper {
		position: absolute;
		top: 4px;
		left: 4px;
		text-align: center;
		min-width: 95px;
		min-height: 95px;
		background: white;
	}

	.bookLoading {
		position: relative;
		top: 15px;
	}
}
