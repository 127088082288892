$primary: #30ccc2;
.ant-layout {
	max-height: initial !important;
}
.ant-layout-content {
	display: flex;
	align-items: stretch;
}
#bookView {
	.titleWrapper {
		text-align: center;
		img {
			width: 120px;
		}
	}

	// .centerContainer {
	// 	flex: 1;
	// 	display: flex;
	// 	justify-content: center;
	// 	min-height: 180px;
	// 	align-items: center;
	// 	flex-direction: column;
	// }

	.pagesCountWrapper {
		align-self: flex-start;
		padding-left: 6;
		flex: 1;
		justify-self: flex-start;
	}
	.emptyBookWrapper {
		text-align: center;
	}

	.buttonWrapper {
		text-align: center;
		position: fixed;
		width: 100%;
		left: 0;
		bottom: 15px;
		z-index: 1;
	}
}
