$primary: #30ccc2;

.pageDetails {
	position: relative;

	.pageNumberWrapper {
		min-height: 35px;
		line-height: 35px;
	}

	.openMenuBtn {
		width: 35px;
		height: 35px;
		position: absolute;
		right: 20px;
		cursor: pointer;
		transition: transform 500ms;
		&.open {
			transform: rotate(180deg);
		}
	}

	.menuWrapper {
		width: 180px;
		display: inline-block;
		border-radius: 20px;
		background-color: white;
		position: absolute;
    z-index: 1;
    top: 35px;
    right: 25px;
    border: 1px solid lightgray;
    box-shadow: -2px 3px 4px rgba(0, 0, 0, 0.3);
    user-select: none;

		.menuButton {
			width: 100%;
			text-align: left;
			padding: 10px 20px;
			cursor: pointer;
			border-bottom: 1px solid lightgray;
			border-left: none;
			border-right: none;
			border-top: none;
			background-color: white;
			transition: all 0.15s linear;
  		transition: background 0.8s;

			&:active,
		  &:focus {
		    outline: none;
		  }

			&:hover {
	      background: darken(white, 2%)
	        radial-gradient(circle, transparent 1%, darken(white, 2%) 1%)
	        center/15000%;
	      cursor: pointer;
	    }

	    &:active {
	      background-color: darken(white, 20%);
	      background-size: 100%;
	      transition: background 0s;
    	}

			&:first-child {
				border-top-left-radius: 20px;
    		border-top-right-radius: 20px;
			}
			&:last-child {
				border-bottom-left-radius: 20px;
    		border-bottom-right-radius: 20px;
				border: none;
			}
			.menuIconWrapper {
				display: inline-block;
				vertical-align: middle;
				padding-right: 10px;
				color: $primary;
				&.del {
					color: red;
				}
			}
			.menuText {
				display: inline-block;
			}
		}
	}
}
