#orderSubscriptionView {
  .contentTitle {
    font-size: 22px;
  }

  .titleImage {
    width: 220px;
    min-height: 165px;
  }

  .infoText {
    color: black;
  }

  .subscriptionPriceWrapper {
    text-align: center;
    font-size: 22px;
    .price {
      font-size: 25px;
    }
  }

  .benefitWrapper {
    .imageWrapper {
      display: inline-block;
      img {
        width: 120px;
        height: 120px;
      }
    }
    .textWrapper {
      display: inline-block;
      width: 230px;
      vertical-align: middle;
      padding-left: 15px;
      b {
        color: black;
      }
    }
  }

  .titleWrapper {
    text-align: center;
  }

  .titleImageWrapper {
    text-align: center;
  }

  .buttonWrapper {
    text-align: center;
  }
}
