$primary: #30ccc2;
$secondary: #f9a02b;

#navigationBar {
  &.navigationBarWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-bottom: 2px solid $primary;
    background: white;
    z-index: 2;
  }

  .root {
    text-align: center;
    max-width: 420;
    margin: 0 auto;
  }

  .btnWrapper {
    font-size: 13px;
  }

  .menuItemIcon {
    color: $primary;
  }

  .menuIcon {
    font-size: 24px;
    margin-right: 16px;
  }

  .divider {
    background-color: "rgba(0, 0, 0, 0.12)";
  }

  .fileInputLabel {
    display: block;
    margin: 0;
  }

  .fileInputWrapper {
    display: block;
    width: 0;
    height: 0;
  }
}
