.ant-layout-content {
	display: flex;
	align-items: stretch;
}
#onboardingImportPhotos {
	.centerContainer {
		flex: 1;
		display: flex;
		justify-content: center;
		min-height: 300px;
		align-items: center;
		padding: 0 25px;
		flex-direction: column;
	}

	.contentTitle {
		font-size: 22px;
	}

	.titleWrapper {
		text-align: center;
	}

	.titleImageWrapper {
		text-align: center;
		min-height: 170px;
	}

	.bottomContainer {
		display: flex;
		min-height: 35px;
		flex-direction: column;
		flex: 0.5;
		justify-content: flex-end;
		text-align: center;
	}

	.titleImage {
		width: 220px;
		min-height: 170px;
	}

	#fileUpload {
		display: none;
	}
	.loginComponentWrapper {
		.customBtn {
			font-size: 14px;
			font-weight: bolder;
			padding: 0 10px;
		}
	}
}
