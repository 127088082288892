$primary: #30ccc2;
$secondary: #f9a02b;

.customBtn {
  @mixin shadow($color: transparent, $fade: 0) {
    box-shadow: 0 0 10px 0.5px rgba($color, $fade);
  }
  position: relative;
  padding: 0 10px;
  margin: 0;
  width: 250px;
  height: 45px;
  border: none;
  background: $primary;
  &.secondary {
    background: $secondary;
  }
  background-position: center;
  color: white;
  @include shadow(gray, 0.2);
  transition: all 0.15s linear;
  transition: background 0.8s;
  border-radius: 5px;

  &.small {
    width: 150px;
    font-size: 12px;
    height: 34px;
  }

  &:active,
  &:focus {
    outline: none;
  }

  &:not(:disabled):hover {
    @include shadow(black, 0.2);
    cursor: pointer;
  }

  &.secondary:not(:disabled):hover {
    background: lighten($secondary, 5%)
      radial-gradient(circle, transparent 1%, lighten($secondary, 5%) 1%)
      center/15000%;
  }

  &:not(:disabled):not(.secondary):hover {
    background: lighten($primary, 5%)
      radial-gradient(circle, transparent 1%, lighten($primary, 5%) 1%)
      center/15000%;
  }

  &.secondary:not(:disabled):active {
    background-color: lighten($secondary, 35%);
    background-size: 100%;
    transition: background 0s;
  }

  &:not(:disabled):not(.secondary):active {
    background-color: lighten($primary, 35%);
    background-size: 100%;
    transition: background 0s;
  }

  span {
    margin: 0;
    z-index: 1;
  }

  &.roundCustomBtn {
    border-radius: 50px;
  }

  &:disabled {
    color: rgba(0, 0, 0, 0.26);
    box-shadow: none;
    background-color: #d3d3d3;
  }

  .loadingWrapper {
    position: absolute;
    width: 30px;
    height: 30px;
    top: calc(50% - 15px);
    left: calc(50% - 15px);
    display: inline-block;
    animation: rotationAnimation 1.4s linear infinite;
    color: $primary;
    &.secondary {
      color: $secondary;
    }
    line-height: 1;

    .loadingCircle {
      animation: circleDashes 1.4s ease-in-out infinite;
      stroke-dasharray: 80px, 200px;
      stroke-dashoffset: 0px;
      stroke: currentColor;
    }
  }

  &.hollowCustomBtn {
    background-color: white;
    border: 2px solid $primary;
    color: $primary;

    &.secondary {
      border: 2px solid $secondary;
      color: $secondary;
    }

    &:not(:disabled):hover {
      border-color: lighten($primary, 2%);
      &.secondary {
        border-color: lighten($secondary, 2%);
      }
      background: darken(white, 2%)
        radial-gradient(circle, transparent 1%, darken(white, 2%) 1%)
        center/15000%;
      cursor: pointer;
    }

    &:not(:disabled):active {
      background-color: darken(white, 20%);
      background-size: 100%;
      transition: background 0s;
    }

    &:disabled {
      border: 1px solid rgba(0, 0, 0, 0.23);
      color: rgba(0, 0, 0, 0.26);
    }
  }

  &.clearCustomBtn {
    width: initial;
    background-color: white;
    color: $primary;
    &.secondary {
      color: $secondary;
    }
    box-shadow: initial;
    padding: 0 20px;

    &:not(:disabled):hover {
      box-shadow: initial;
      background: darken(white, 2%)
        radial-gradient(circle, transparent 1%, darken(white, 2%) 1%)
        center/15000%;
      cursor: pointer;
    }

    &:not(:disabled):active {
      background-color: darken(white, 20%);
      background-size: 100%;
      transition: background 0s;
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.26);
    }
  }
}

@keyframes rotationAnimation {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes circleDashes {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0px;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -120px;
  }
}

.loadMoreBtn {
  position: relative;
  perspective: 500px;
  transform-style: preserve-3d;
  .paper {
    left: 8px;
    position: absolute;
    width: 103px;
    height: 103px;
    padding: 4px;
    cursor: pointer;
    margin: 0 auto;
    user-select: none;
    text-align: center;
    transition: transform 1s;
    backface-visibility: hidden;
    &.back {
      padding-top: 20px;
      cursor: default;
      transform: rotateY(180deg);
    }
    p {
      font-size: 12px;
    }
  }

  transition: transform 0.5s;

  &.disabled {
    cursor: not-allowed;
    .paper {
      cursor: not-allowed;
      .image {
        filter: grayscale(100%);
      }
    }
  }

  .image {
    width: 60%;
    height: 60%;
    margin-left: 10px;
    margin-top: 10px;
    object-fit: cover;
  }

  .progressWrapper {
    width: 103px;
    padding-top: 5px;
    margin: 0 auto;
  }

  .progressColorSecondary {
    background-color: #c8e6c9;
  }

  .progressBarColorSecondary {
    background-color: #4caf50;
  }

  .bookLoadingWrapper {
    position: absolute;
    top: 4px;
    left: 4px;
    text-align: center;
    min-width: 95px;
    min-height: 95px;
    background: white;
  }

  .bookLoading {
    position: relative;
    top: 15px;
  }

  .instagramButton,
  .deviceButton {
    position: absolute;
    width: 100%;
    height: 50%;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    .content {
      text-align: center;
      img {
        width: 20px;
      }
      .text {
        color: white;
        display: inline-block;
        vertical-align: middle;
        text-align: center;
        width: 65px;
        font-size: 9px;
      }
    }
  }

  .deviceButton {
    top: 0;
    background-color: $primary;
    &:hover {
      background-color: darken($primary, 10%);
    }
  }

  .instagramButton {
    top: 50%;
    background-color: $secondary;
    &:hover {
      background-color: darken($secondary, 10%);
    }
  }

  &.big {
    width: 300px;
    height: 300px;
    max-width: initial;
    flex-basis: initial;
    margin: 0 auto;
    .paper {
      left: 0;
      width: 300px;
      height: 300px;

      .image {
        margin-top: 30px;
      }

      .text {
        font-size: 30px;
      }

      &.back {
        padding-top: 95px;
      }
    }

    .instagramButton,
    .deviceButton {
      .content {
        img {
          width: 50px;
        }
        .text {
          width: 208px;
        }
      }
    }
  }
}

.switch {
  flex-direction: row-reverse;
  .text {
    font-family: "Poppins", sans-serif;
    color: #969696;
  }
  .iOSSwitchBase {
    &$iOSChecked {
      color: white;
      & + $iOSBar {
        background-color: #52d869;
      }
    }
    transition: transform 150ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
  }
  .iOSChecked {
    transform: translateX(15px);
    & + .iOSBar {
      opacity: 1;
      border: none;
      background-color: #52d869 !important;
    }
  }
  .iOSBar {
    border-radius: 13px;
    width: 42px;
    height: 26px;
    margin-top: -13px;
    margin-left: -21px;
    border: solid 1px;
    border-color: #bdbdbd;
    background-color: white;
    opacity: 1;
    transition: background-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
      border 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  }
  .iOSIcon {
    width: 24px;
    height: 24px;
  }
  .iOSIconChecked {
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2),
      0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 2px 1px -1px rgba(0, 0, 0, 0.12);
    background-color: white;
  }
}
