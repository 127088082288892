.ant-layout-content {
	display: flex;
	align-items: stretch;
}
#chooseCovers {
	.centerContainer {
		flex: 1;
		display: flex;
		justify-content: center;
		min-height: 180px;
		align-items: center;
		flex-direction: column;
	}

	.titleWrapper {
		text-align: center;
	}

	.contentTitle {
		font-size: 22px;
	}

	.coverWrapper {
		width: 250px;
		height: 250px;
		margin: 0 auto;
		.coverContainer {
			position: relative;
			width: 100%;
			height: 100%;
			box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.2),
				0px 2px 2px 0px rgba(0, 0, 0, 0.14),
				0px 3px 1px -2px rgba(0, 0, 0, 0.12);
			padding: 32px 32px 32px 34px;

			.coverCrease {
				position: absolute;
				left: 10px;
				top: 0px;
				bottom: 0px;
				width: 1px;
				background: #e2e2e2;
				z-index: 2;
			}

			.imagesWrapper {
				width: 186px;
				height: 186px;
			}

			.imgContainer {
				display: inline-block;
				width: 25%;
				height: 25%;
				padding: 1px;
				img {
					object-fit: cover;
					width: 100%;
					height: 100%;
				}
			}
		}
		.backCoverContainer {
			width: 150px;
    	height: 143px;
    	margin: 18px 0px 0px 15px;
			.backCoverLogoContainer {
				position: absolute;
				left: 0;
				bottom: 20px;
				width: 100%;
				text-align: center;

				img {
					width: 50px;
				}
			}

			.backCoverImageWrapper {
				width: 150px;
				height: 125px;
				text-align: center;
				img {
					width: 120px;
					height: 120px;
					object-fit: cover;
				}
			}
			.mgs {
				text-align: center;
				font-size: 0.9em;
			}
		}
	}

	.bottomContainer {
		display: flex;
		min-height: 35px;
		flex-direction: column;
		flex: 0.5;
		justify-content: flex-end;
		text-align: center;
	}

	.pageLoadingWrapper {
		width: initial !important;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background: white;
		margin: 0 !important;
		.pageLoadingGraphic {
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			margin: auto;
		}
	}
}
