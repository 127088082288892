@import '~antd/dist/antd.css';
@import '~react-image-crop/dist/ReactCrop.css';
@import '~react-alice-carousel/lib/alice-carousel.css';

@import './variables.scss';
@import './styles/BookCreationLoading.scss';
@import './styles/OnboardingImportPhotos.scss';
@import './styles/Buttons.scss';
@import './styles/KeepCaptions.scss';
@import './styles/ChooseCovers.scss';
@import './styles/Steps.scss';
@import './styles/SelectPhotos.scss';
@import './styles/BookView.scss';
@import './styles/NavigationBar.scss';
@import './styles/PhotosUpload.scss';
@import './styles/BookTitle.scss';
@import './styles/Covers.scss';
@import './styles/PageDetails.scss';
@import './styles/OrderSubscriptionView.scss';
@import './styles/CheckoutOrderReceived.scss';
@import './styles/Login.scss';
@import './styles/Account.scss';
@import './styles/Unsubscribe.scss';

html, body {
  touch-action: manipulation;
  margin: 0;
  padding: 0;
  font-family: sans-serif;
  // background-color: #F9F9F9;

  &.noscroll {
    overflow: hidden;
  }
}

.App {
  // font-family: 'Open Sans', 'Arial', sans-serif;
  // match the office ui fabric fonts
  font-family: "Segoe UI Web (West European)", "Segoe UI", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;

  display: flex;
  flex-direction: row;
  min-height: 100vh;
}

.App--content {
  position: relative;
  // width: 100%;
  // max-width: 1024px;
  flex: 1;
  margin: 0px auto;
  flex: 1 0 auto;
  min-height: 1000px;
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  width: 375px;
  padding: 12.5px;
  margin: 0 auto;
  font-family: 'Poppins', sans-serif;
  color: #969696; }

.contentTitle {
  display: flex;
  justify-content: center;
  font-size: 18px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.topNavbar {
  display: flex;
  height: 50px;
  flex-shrink: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: #30CCC2;
  align-items: center;
  border-top: 1px solid #ccc;
  justify-content: space-around;
  z-index: 3;
  color: #fff;
  font-size: 14px;
}

.bottomNavbar {
  display: flex;
  height: 50px;
  flex-shrink: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: #F9A02B;
  align-items: center;
  border-top: 1px solid #ccc;
  justify-content: space-around;
  z-index: 3;
  color: #fff;
  font-size: 14px;
}

.navbarItem {
  display: flex;
  flex-grow: 1;
  justify-content: center;
}

// .btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited {
//   background-color: #30CCC2 !important;
// }

.bigButtonWrapper {
  display: flex;
  justify-content: center;
  width: 220px;
  margin: 0 auto; }

.smallButtonWrapper {
  display: flex;
  justify-content: center;
  width: 150px;
  margin: 0 auto; }

.facebook {
  width: 300px;
  background-color: '#fff';
  border-color: '#30ccc2';
  color: '#30ccc2'; }

.dividerTitle {
  text-align: center;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  margin: 16px 0;
}

.contentTitleImageWrapper {
  margin-top: 25px;
  margin-bottom: 25px;
  margin: 0 auto;
}

.contentTitleImage {
  width: 100px;
  margin: 0 auto;
}
