$primary: #30ccc2;
.ant-layout {
	max-height: initial !important;
}
.ant-layout-content {
	display: flex;
	align-items: stretch;
}
#selectPhotos {
	.titleWrapper {
		text-align: center;
		.contentTitle {
			font-size: 22px;
			margin: 0;
			.titleCheckIconWrapper {
				display: flex;
				align-items: center;
				margin-right: 10px;
				color: $primary;
			}
			.subtitle {
				align-items: center;
			}
		}
	}

	// .centerContainer {
	// 	flex: 1;
	// 	display: flex;
	// 	justify-content: center;
	// 	min-height: 180px;
	// 	align-items: center;
	// 	flex-direction: column;
	// }

	.buttonWrapper {
		text-align: center;
		position: fixed;
		width: 100%;
		left: 0;
		bottom: 15px;
		z-index: 3;
		.importPhotosBtnWrapper {
			margin-bottom: 5px;
		}
		.loadMoreBtnContainer {
			position: relative;
			display: inline-block;
			width: 350px;
			height: 34px;
		}
	}
	svg.checkIcon {
		background: linear-gradient(white, white) no-repeat;
		background-position: 5px 5px;
		background-size: 14px 14px;
	}

	.selectButtonsWrapper {
		position: relative;
		width: 100%;
		height: 50px;
		flex: 1;
	}

	.leftBtnContainer,
	.rightBtnContainer {
		display: inline-block;
		position: absolute;
	}

	.leftBtnContainer {
		left: 10px;
	}

	.rightBtnContainer {
		right: 10px;
	}

	.pageLoadingWrapper {
		flex: 1;
		margin: 0 !important;
	}
}
