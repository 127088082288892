$primary: #30ccc2;
$secondary: #f9a02b;
#unsubscribe {
	.backButtonWrapper {
		button {
			line-height: 34px;
			svg, span {
				vertical-align: middle;
			}
			span {
				color: #969696;
				font-weight: bolder;
			}
		}
	}

	.titleImageWrapper {
		text-align: center;
		img {
			width: 220px;
			min-height: 153px;
		}
	}

	.subtitleWrapper {
		text-align: center;
	}

	.buttonsWrapper {
		width: 300px;
		margin: 0 auto;
		.leftButtonWrapper, .rightButtonWrapper {
			display: inline-block;
			width: 50%;
		}
		.leftButtonWrapper {
			text-align: left;
		}
		.rightButtonWrapper {
			text-align: right;
		}
		.customBtn {
			width: 130px;
		}
	}

	.details {
		width: 300px;
		margin: 0 auto;
		text-align: center;
		strong {
			color: #545454;
		}
	}

	.talkToUsWrapper {
		width: 300px;
		margin: 0 auto;
		text-align: center;
		span {
			display: block;
		}
	}
}
