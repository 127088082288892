$primary: #30ccc2;
$secondary: #f9a02b;
#account {
	.backButtonWrapper {
		button {
			line-height: 34px;
			svg, span {
				vertical-align: middle;
			}
			span {
				color: #969696;
				font-weight: bolder;
			}
		}
	}

	.logoWrapper {
		text-align: center;
		img {
			height: 46px;
		}
	}

	.accountDetailsWrapper {
		width: 300px;
		margin: 0 auto;
		.accountDetails {
			.label, .value {
				display: inline-block;
				width: 50%;
			}
			.label {
				text-align: left;
			}
			.value {
				text-align: right;
				color: $primary;
			}
		}
	}

	.toTheRight {
		text-align: right;
		.customBtn {
			line-height: 45px;
			svg, span {
				vertical-align: middle;
			}
			svg {
				margin-top: -2px;
			}
			span {
				color: #969696;
				padding-left: 5px;
				padding-right: 5px;
			}
		}
	}

	.booksButtonsWrapper {
		width: 300px;
		margin: 0 auto;
		.leftButtonWrapper, .rightButtonWrapper {
			display: inline-block;
			width: 50%;
			.customBtn {
				width: 130px;
			}
		}
		.leftButtonWrapper {
			text-align: left;
		}
		.rightButtonWrapper {
			text-align: right;
		}
	}

	.saveButtonWrapper {
		text-align: center;
		.customBtn {
			width: 130px;
		}
	}

	.inputsWrapper {
		width: 300px;
		margin: 0 auto;
	}

	.infoMsg {
		width: 300px;
		margin: 0 auto;
		strong {
			color: #545454;
		}
	}

}
