#checkoutOrderReceived {
	.contentTitle {
		font-size: 22px;
	}

	.titleWrapper {
		text-align: center;
	}

	.titleImageWrapper {
		text-align: center;
	}

	.details {
		font-size: 15px;
		margin: 0;
		text-align: center;
		strong {
			color: #545454;
			font-size: 16px;
			padding: 0 5px;
		}
	}

	.buttonWrapper {
		text-align: center;
	}

	.titleImage {
		width: 220px;
	}

	.footer {
		text-align: center;
		.logo {
			height: 38px;
		}
	}
}
