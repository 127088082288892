$primary: #30ccc2;

#bookTitle {
	&.wrapper {
	}

	.bookTitleTextWrapper {
		padding-left: 78px;
		.bookTitleText {
			cursor: pointer;
			text-align: center;
			width: 194px;
			font-size: 22px;
			display: inline-block;
			height: 46px;
			line-height: 46px;
			word-break: break-all;
		}
	}

	.bookTitleInputWrapper {
		padding-left: 78px;		
		div {
			width: 194px;
		}
		input {
			text-align: center;
			font-size: 22px;
			font-family: 'Poppins', sans-serif;
		}
	}

	.iconBtn {
		vertical-align: text-bottom;
		margin: 0 10px;
		cursor: pointer;
	}

	.editBtn,
	.saveBtn {
		color: green;
	}

	.saveBtn.disabled {
		color: gray;
		cursor: not-allowed;
	}

	.cancelBtn {
		color: red;
		margin-left: 0;
	}
}
