.ant-layout-content {
	display: flex;
	align-items: stretch;
}
#keepCaptions {
	.centerContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0 25px;
		flex-direction: column;
	}

	.contentTitle {
		font-size: 22px;
	}

	.titleWrapper {
		text-align: center;
	}

	.titleImageWrapper {
		text-align: center;
	}

	.bottomContainer {
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		text-align: center;
	}

	.titleImage {
		width: 220px;
		min-height: 180px;
	}
}
