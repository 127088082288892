.ant-layout-content {
	display: flex;
	align-items: stretch;
}
#bookCreationLoading {
	.centerContainer {
		flex: 1;
		display: flex;
		justify-content: center;
		min-height: 140px;
	}

	.titleImageWrapper,
	.bottomImageWrapper {
		text-align: center;
	}

	.titleImage {
		width: 150px;
		min-height: 150px;
	}

	.bottomImage {
		height: 38px;
	}

	.animatedTextWrapper {
		align-self: center;
	}

	.animatedText {
		width: 100%;
	}

	.textContainer {
		display: flex;
		justify-content: center;
		align-items: flex-start;
		flex-direction: column;
	}

	.textContainer div {
		padding-bottom: 12px;
	}

	.text {
		font-size: 1em;
		font-style: italic;
		letter-spacing: 0.15em;
		margin-bottom: 0;
	}

	.typewriter {
		overflow: hidden;
		border-right: 0.15em solid orange;
		white-space: nowrap;
		margin: 0 auto;
		animation: typing 2s steps(40, end), blink-caret 0.75s step-end infinite;
	}

	@keyframes typing {
		from {
			width: 0;
		}
		to {
			width: 100%;
		}
	}

	@keyframes blink-caret {
		from,
		to {
			border-color: transparent;
		}
		50% {
			border-color: orange;
		}
	}
}
