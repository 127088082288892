// for media queries

$media-small-mobile: 'screen and (max-width: 400px)';
$media-mobile: 'screen and (max-width: 768px)';
$media-not-mobile: 'screen and (min-width: 769px)';
$media-tablet: 'screen and (min-width: 768px) and (max-width: 1024px)';
$media-portable: 'screen and (max-width: 1024px)';
$media-desktop: 'screen and (min-width: 1024px)';

$primaryColor: #30ccc2;
$primaryAccentColor: #4fc9c2;
$secondaryColor: #ecb4b4;

$fontWeights: (
  'regular': 400,
  'medium': 500,
  'bold': 700
);

// colours
$white: #fff;
$black: #333333;        // rgb(51, 51, 51)
// $primaryBlue: #0098f1;  // rgb(0, 152, 241)
$primaryBlue: #30CCC2;
$lightBlue: #02a2ff;    // rgb(2, 162, 255)
$darkBlue: #0076df;     // rgb(0, 118, 223)
$accent1: #01aa53;      // rgb(1, 170, 83)      -- green
$accent2: #ffbb00;      // rgb(255, 187, 0)     -- orange
$accent3: #ff3d00;      // rgb(255, 61, 0)      -- red
$whiteGrey: #f6f6f6;    // rgb(246, 246, 246)
$warmGrey: #737373;     // rgb(115, 115, 115)
$midGrey: #9b9b9b;      // rgb(155, 155, 155)
$darkGrey: #4a4a4a;     // rgb(74, 74, 74)
$sliderGrey: #dddddd;   // rgb(221, 221, 221)
$infoButtonYellow: #fff6d9; // -- on claims screen
$infoButtonBorderYellow: rgb(241, 227, 188); // -- on claims screen
$lightRedBg: rgb(255, 247, 245); // error notification
$lightRedOutline: rgb(255, 204, 188); // error notification
$notificationYellow: rgb(255, 245, 217); // warning notification
$lightYellowOutline: rgb(241, 227, 188); // warning notification
$lightGreenBg: rgb(236, 255, 245); // success notification
$lightGreenOutline: rgb(208, 243, 225); // success notification
$reactSelectSelectedBackground: rgba(0, 126, 255, 0.08);
$reactSelectUnselectedText: #666;

// fonts
$headerFont: 'Roboto Slab';
$bodyFont: 'Open Sans';

// font weights, must have these imported from google for
// each font we want to use them for
$fontWeightNormal: 400;
$fontWeightBold: 600;


// mixins for generally reused style
@mixin border($radius: 4px, $color: #ccc) {
  border: 1px solid $color;
  border-radius: $radius;
}

@mixin shadow($opacity: 0.5) {
  box-shadow: 0px 0px 4px rgba(127, 127, 127, $opacity);
}

@mixin colorShadow($color: rgba(127, 127, 0.5)) {
  box-shadow: 0px 0px 4px $color;
}


// mixins for different text types based on styleguide

@mixin _HeaderBase() {
  color: $black;
  font-family: $headerFont;
  font-weight: $fontWeightNormal;
}

@mixin _HeroBase() {
  @include _HeaderBase();
  font-size: 34px;
  line-height: 44px;
}

@mixin Hero_Bold_Centered_Black() {
  @include _HeaderBase();
  font-weight: $fontWeightBold;
  text-align: center;
}

@mixin Hero_Normal_Left_Aligned_Black() {
  @include _HeaderBase();
}


@mixin Hero_Normal_Centered_Black() {
  @include _HeaderBase();
  text-align: center;
}

@mixin Header1_Normal_Centered_Black() {
  @include _HeaderBase();
  font-size: 24px;
  line-height: 36px;
  text-align: center;
}

@mixin Header2_Normal_Centered_Black() {
  @include _HeaderBase();
  font-size: 20px;
  line-height: 32px;
  text-align: center;
}

@mixin Header3_Bold_Centered_Black() {
  @include _HeaderBase();
  font-weight: $fontWeightBold;
  font-size: 28px;
  line-height: 26px;
  text-align: center;
}

@mixin Header3_Normal_Centered_Black() {
  @include _HeaderBase();
  font-size: 28px;
  line-height: 26px;
  text-align: center;
}

@mixin _BodyBase() {
  text-align: left;
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-weight: $fontWeightNormal;
  color: $black;
  font-size: 16px;
  line-height: 24px;
}

@mixin Body_Bold_Centered_PrimaryBlue() {
  @include _BodyBase();
  font-weight: $fontWeightBold;
  text-align: center;
  color: $primaryBlue;
}

@mixin Body_Bold_Centered_Black() {
  @include _BodyBase();
  font-weight: $fontWeightBold;
  text-align: center;
}

@mixin Body_Normal_Centered_Black() {
  @include _BodyBase();
  text-align: center;
}

@mixin Body_Normal_Left_Aligned_Black() {
  @include _BodyBase();
}

@mixin Body_Normal_Centered_PrimaryBlue() {
  @include _BodyBase();
  text-align: center;
  color: $primaryBlue;
}

@mixin Body_Normal_Left_Aligned_Grey() {
  @include _BodyBase();
  color: $midGrey; // @todo
}

@mixin _ParagraphBase() {
  text-align: left;
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-weight: $fontWeightNormal;
  color: $black;
  font-size: 14px;
  line-height: 22px;
}

@mixin Paragraph_Bold_Centered_Black {
  @include _ParagraphBase();
  text-align: center;
  font-weight: $fontWeightBold;
}

@mixin Paragraph_Normal_Left_Aligned_Grey {
  @include _ParagraphBase();
  color: $midGrey;
}

@mixin Paragraph_Normal_Centered_PrimaryBlue {
  @include _ParagraphBase();
  text-align: center;
  color: $primaryBlue;
}

@mixin Paragraph_Normal_Centered_Black() {
  @include _ParagraphBase();
  text-align: center;
}

@mixin _CaptionBase() {
  text-align: left;
  font-family: 'Open Sans', 'Arial', sans-serif;
  font-weight: $fontWeightNormal;
  color: $black;
  font-size: 12px;
  line-height: 16px;
}

@mixin Caption_Bold_Left_aligned_PrimaryBlue() {
  @include _CaptionBase();
  font-weight: $fontWeightBold;
  color: $primaryBlue;
}

@mixin Caption_Bold_Centered_Black() {
  @include _CaptionBase();
  font-weight: $fontWeightBold;
  text-align: center;
}

@mixin Caption_Normal_Left_aligned_Grey() {
  @include _CaptionBase();
  color: $midGrey;
}

@mixin Caption_Normal_Centered_Black() {
  @include _CaptionBase();
  text-align: center;
}

@mixin Caption_Normal_Left_aligned_Accent3() {
  @include _CaptionBase();
  color: $accent3;
}
